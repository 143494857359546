@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-inter;
    pointer-events: auto !important;
}

button {
    @apply font-inter;
}


.btnPrimary {
    color: white;
    background-color: #DF7A5E;
    border-radius: 8px;
    width: 100%;
    padding: 12px 12px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnPrimary:hover {
    box-shadow: 4px 4px 8px 0px #00000040;

}

.btnSimple {
    color: #686C82;
    border: 1px solid #686C82;
    border-radius: 8px;
    width: 100%;
    padding: 11px 12px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnSimple:hover {
    box-shadow: 4px 4px 8px 0px #00000040;

}



/* .centerBanner {
    background-image: url("/public/images/center-banner-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
} */

/* .ParentBanner {
    background-image: url("/public/images/parent-banner-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
} */

.ViewCenter {
    background-image: url("https://a360csastorage.blob.core.windows.net/childcare/e2717037-6870-48c5-9f92-cfc28a47f07e-fileName-profile-img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

/* .adventureEpisode {
    background-image: url("/public/images/Adventures_images/adventure-episode-banner-img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .aboutUsBanner {
    background-image: url("/public/images/aboutUs-banner-img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

.ccfHomeBanner {
    background-image: url("https://a360csastorage.blob.core.windows.net/childcare/ed096856-ac81-4bc5-81c9-47a0376950fb-fileName-banner-bg-img.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
}

/* .contactUsbanner {
    background-image: url("/public/images/contactUs-banner-img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .ccf-center-banner {
    background-image: url("/public/images/CCF_images/ccf-center-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .ccf-parent-banner {
    background-image: url("/public/images/CCF_images/ccf-parent-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .ccf-teacher-banner {
    background-image: url("/public/images/CCF_images/ccf-teacher-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .medEvaluationDateBanner {
    background-image: url("/public/images/MedEvaluation_images/med-evaluation-date-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .bookingSuccessful {
    background-image: url("/public/images/MedEvaluation_images/bookingSuccessBanner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .consultationbanner {
    background-image: url("/public/images/consultation-banner-img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .blogsbanner {
    background-image: url("/public/images/blogsBanner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

.swiper-pagination-bullet {
    background-color: white;
    opacity: 100;
}

.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #DF7A5E;
    width: 12px;
    height: 12px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swipercustom-prev,
.swipercustom-next {
    position: absolute;
    top: -30px;
    right: 40px;
    transform: translateY(-50%);
    z-index: 100;
    cursor: pointer;
    left: auto;
}

.swipercustom-next {
    right: 10px;
}

.swipercustom-prev img,
.swipercustom-next img {
    width: 30px;
    height: 30px;
}

.scrollHide ::-webkit-scrollbar {
    display: none;
}

.scrollHide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}

/* .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
} */

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #BEBFCD;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #686C82;
}



.css-1u9des2-indicatorSeparator {
    width: 0 !important;
}

.css-1xc3v61-indicatorContainer {

    color: #686C82 !important;

}

.childRange.mt-2.css-b62m3t-container .css-1nmdiq5-menu {
    top: 100%;
    right: 0;
    position: absolute;
    width: 64%;
    z-index: 1;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 0px;
    margin-top: 1px;
    box-sizing: border-box;
}

.childRange.mt-2.css-b62m3t-container .css-1n6sfyn-MenuList {
    max-height: 325px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding: 10px 6px !important;
    box-sizing: border-box;
}

.childRange.mt-2.css-b62m3t-container .css-10wo9uf-option {

    color: #686C82;

}

.styles-module_dark__xNqje {
    background: #DF7A5E !important;
    color: var(--rt-color-white);
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100% !important;
}

.forwardText {
    color: #DF7A5E;
    font-style: italic;
}


@media screen and (-webkit-min-device-pixel-ratio: 0) {

    input[type="range"]::-webkit-slider-thumb {
        width: 15px;
        -webkit-appearance: none;
        appearance: none;
        height: 15px;
        cursor: ew-resize;
        border-radius: 50%;
        @apply bg-primary;

    }
}





@media (max-width: 767.98px) {
    .btnPrimary {
        padding: 10px 10px;
        font-size: 16px;
        font-weight: 500;

    }

    .btnSimple {

        padding: 10px 10px;
        font-size: 16px;

    }
}



.scroll::-webkit-scrollbar {
    width: 10px;
}


.scroll::-webkit-scrollbar-thumb {
    background: #BEBFCD;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.scroll::-webkit-scrollbar-thumb:hover {
    background: #686C82;
}


.bg-bannerGradiant {
    background: linear-gradient(to left, rgba(244, 241, 222, 0.62) 0%, rgba(244, 241, 222, 0.62) 56%, rgba(244, 241, 222, 0.62) 73%, rgba(244, 241, 222, 1) 100%);
}

.dropdown-menu {
    display: none;
}

.dropdown-menu.show {
    display: block;
}


.groupBTn:hover svg path {
    stroke: #df7a5e;
}


.slider {
    appearance: none;
    height: 8px;
    border-radius: 5px;
    cursor: pointer;
  }
  .slider::-webkit-slider-thumb {
    appearance: none;
    width: 18px;
    height: 18px;
    background-color: #3490dc; 
    border-radius: 50%; 
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }
  .slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background-color: #3490dc; 
    border-radius: 50%; 
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }
  .slider:focus::-webkit-slider-thumb {
    outline: 2px solid #000; 
  }
  .slider:focus::-moz-range-thumb {
    outline: 2px solid #000; 
  }

  .accordion-collapse.collapse{
    display: none;
  }
  .accordion-collapse.collapse.show{
    display: block;
  }

  .acc_btn .collapsed .icon svg{
    transform: rotate(0deg);
  }

  .acc_btn  .icon svg{
    transform: rotate(180deg);
  }

  .acc_btn button{
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
  }

  .menuRight .dropDown{
    left: 0 !important;
    right:  0 !important;
    top: 100% !important;
    inset: inherit !important;
    transform: inherit !important;
    right: 0 !important;
  }

  .menuRight .dropDown.show{
    display: flex !important;
    align-items: flex-start;
  }


  .scrollWrapper{
    width: max-content;
  }
  .scrollWrapper .scrollHide {
    overflow-y: scroll;
  }

.bg-white.react-tooltip {
  background: #fff !important;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  animation: marquee 25s linear infinite;
}


.animate-marquee:hover{
  animation-play-state: paused;
}